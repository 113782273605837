import React from 'react'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'

import './App.css'
import Home from './components/home'
import About from './components/about'

// test section
import Dashboard from "./components/dashboard"

function App() {
  return (
    <Router>
      <div className="App">
        <div className="row top-bar">
          <div className="col">
            <div className="nav">
              <div className="nav-item">
                <Link to="/">home</Link>
              </div>
              <div className="nav-item">
                <Link to="/about">about</Link>
              </div>
              <div className="nav-item">
                <a
                  href="mailto:prestontallen@gmail.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fas fa-envelope" />
                </a>
              </div>
              <div className="nav-item">
                <a
                  href="http://github.com/prestontallen"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-github-alt" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/dashboard">
            <Dashboard />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  )
}

export default App
