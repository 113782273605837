import React, { useReducer } from "react"
import connectClient from "../services/mqtt"

let Client = connectClient()

let dashboardReducer = (state, { channel, payload }) => {
  switch (channel) {
    case '/home/livingroom/temp':
      return { ...state, temp: payload }
    case '/home/livingroom/humidity':
      return { ...state, humidity: payload }
    case '/home/livingroom/pressure':
      return { ...state, pressure: payload }
    default:
      throw new Error("unknown channel")
  }
}

const Dashboard = () => {
  let [state, dispatch] = useReducer(dashboardReducer, { temp: "offline", humidity: "offline", pressure: "offline" })
  Client.on('message', (topic, message) => {
    dispatch({ channel: topic, payload: message.toString() })
  })

  return (
    <div className="dashboard row">
      <div className="status col">
        temp: {state.temp}
      </div>
      <div className="status col">
        humidity: {state.humidity}
      </div>
      <div className="status col">
        pressure: {state.pressure}
      </div>
    </div>
  )
}

export default Dashboard