import React from 'react'

const Card = ({ title, link, content }) => {
  return (
    <div
      className="card center"
      onClick={() => window.open(`${link}`, '_blank')}
    >
      <p className="card-header">{title}</p>
      <div className="card-content">
        <p>{content}</p>
      </div>
    </div>
  )
}

export default Card
