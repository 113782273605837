import React from 'react'
import Card from './card'
import MainDisplay from './mainDisplay'

const Home = () => {
  return (
    <MainDisplay>
      <div className="col">
        <Card
          title="AWS"
          link="https://console.aws.amazon.com/"
          content="aws login"
        />
        <Card
          title="Docker hub"
          link="https://hub.docker.com/"
          content="docker hub search"
        />
      </div>
      <div className="col">
        <Card
          title="Github"
          link="https://github.com"
          content="github repositories"
        />
        <Card
          title="Gmail"
          link="https://gmail.com"
          content="gmail web client"
        />
      </div>
      <div className="col">
        <Card
          title="DevDocs"
          link="https://devdocs.io"
          content="devdocs documentation"
        />
        <Card
          title="Google Calendar"
          link="http://calendar.google.com"
          content="google calendar"
        />
      </div>
      <div className="col">
        <Card
          title="VSCode"
          link="https://code.visualstudio.com/download"
          content="download vs-code"
        />
      </div>
    </MainDisplay>
  )
}

export default Home
