import React from 'react'
import MainDisplay from './mainDisplay'

const About = () => {
  return (
    <MainDisplay>
      <code>about page in progress..</code>
    </MainDisplay>
  )
}

export default About
