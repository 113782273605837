import mqtt from "mqtt"

const connectClient = () => {
  let options = {
    clean: false,
    clientId: `mqttjs_${Math.random().toString(16).substr(2, 8)}_ptallen.dev`,
    host: process.env.REACT_APP_MQTT_HOST,
    port: 61619,
    username: process.env.REACT_APP_MQTT_USER,
    password: process.env.REACT_APP_MQTT_PASSWD,
    protocol: 'wss'
  }

  let client = mqtt.connect(options)
  client.on('connect', () => {
    client.subscribe(['/home/livingroom/temp', '/home/livingroom/humidity', '/home/livingroom/pressure'], (err) => {
      if (!err) {
        console.log("connected!")
      } else {
        console.log(err)
      }
    })
  })

  return client
}

export default connectClient
